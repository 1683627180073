<template>
  <b-card class="p-2 p-lg-4 w-100">
    <b-card-title
      title-tag="h2"
      class="font-weight-bold mb-1"
    >
      Необходимо подтвердить почту
    </b-card-title>
    <b-card-text class="mb-3">
      На вашу почту {{ currentUser.email }} была отправлена ссылка
    </b-card-text>
    <div class="d-flex">
      <b-button
        class="mr-2"
        variant="primary"
        @click.prevent="sendLink"
      >
        Отправить ссылку повторно
      </b-button>
      <b-button
        variant="outline-primary"
        @click.prevent="logout"
      >
        Выйти
      </b-button>

    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardText, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import Auth from '@/api/http/models/auth/Auth'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
    BButton,
  },
  computed: {
    ...mapGetters({
      currentUser: 'user/currentUser',
      isEmailVerified: 'user/isEmailVerified',
    }),
  },
  methods: {
    async sendLink() {
      const response = await Auth.verifyResend()

      if (response.status === 'success') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ссылка отправлена на почту',
            variant: 'success',
          },
        })
      }
    },
    async logout() {
      const response = await Auth.logout()

      if (response.status === 'success') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Вы успешно вышли',
            variant: 'success',
          },
        })
        document.location = '/'
      }
    },
  },
}
</script>
